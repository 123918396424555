import {Link} from 'react-router-dom'
import logo from '../assets/images/logo.png'
import dd from '../assets/images/dd.png'

function Navbar(){
    return (
        <nav className='nav' >
        <div className='nav-center'>
          <div className='nav-header'>
            <img src={logo} className='nav-logo' alt='' /><span className='font-aller'><Link style={{color:'#000'}} to='/statistic'>Spotify short link</Link></span>

          </div>
          <ul className='nav-links'>
            <li>
              {/* <Link className='link-btn' to="/statistic"> */}
              {/* Statistic */}
              {/* </Link> */}
            </li>
            <li>
              <button className='link-btn' >
                {/* Function */}
              </button>
            </li>

          </ul>
         
          {/* <Link className='btn-c signin-btn' to="/login">Sign in</Link> */}
          <span>Powered By <img src={dd} className='dd-logo'></img></span> 
        </div>
      </nav>  
    )
}
export default Navbar