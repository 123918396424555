import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useNavigate   } from "react-router-dom";

// jeckwin222@gmail.com
const clientId = "127991228813-45t1q3r7kj748risi5gefc3bc9vo4den.apps.googleusercontent.com";

function Login() {
    const [showloginButton, setShowloginButton] = useState(true);
    const [showlogoutButton, setShowlogoutButton] = useState(false);
    const [authed, setAuthed] = useState(false);
    const navigate = useNavigate();
    const onLoginSuccess = (res) => {
        console.log('Login Success:', res);
        setShowloginButton(false);
        setShowlogoutButton(true);
        // localStorage.setItem('google_auth', JSON.stringify(res));
        navigate("/dashboard");
        setAuthed(true);
        
    };

    const onLoginFailure = (res) => {
        console.log('Login Failed:', res);
    };

    const onSignoutSuccess = () => {
        alert("You have been logged out successfully");
        console.clear();
        setShowloginButton(true);
        setShowlogoutButton(false);
        setAuthed(false);
        
    };

    return (
        <div className="g-signin">


            <div className="container-fluid">

                <div className="custom-card">

                    <div className="form-group m-b-1r">
                        <input type="email"
                            placeholder="Enter email address"
                            className="form-control"
                        />
                    </div>

                    <div className="form-group m-b-1r">
                        <input type="password"
                            placeholder="Enter password"
                            className="form-control"
                        />
                    </div>
                    <div className="m-b-1r">
                        <button style={{width:'100%'}} className="btn btn-block btn-primary">Login</button>

                    </div>

                    <p className="horizontal-line-text">
                        <span>or</span>
                    </p>
                    {showloginButton ?
                        <GoogleLogin
                            clientId={clientId}
                            buttonText="Sign In with Google"
                            onSuccess={onLoginSuccess}
                            onFailure={onLoginFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                            style={{ width: '100%' }}
                        /> : null}

                    {showlogoutButton ?
                        <GoogleLogout
                            clientId={clientId}
                            buttonText="Sign Out"
                            onLogoutSuccess={onSignoutSuccess}
                        >
                        </GoogleLogout> : null
                    }


                </div>

            </div>

        </div>
    );
}
export default Login;