import axios from 'axios';
const API_URL = 'https://sptlnk.com/add-custom';
const headers = {
    
  }
const sendSortLink = (url, custom) => {
    return axios
        .post(API_URL, {
            url,
            custom,
        },{headers: headers})
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
                console.log(response.data);
            }

            return response.data;
        });
}
export default sendSortLink;