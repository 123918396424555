import { useState, useEffect } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { MdBarChart } from 'react-icons/md'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, ArcElement
);
// const url = 'https://course-api.com/react-tabs-project';
const url = 'https://sptlnk.com/get-list-custom/';
const urlDetail = 'https://sptlnk.com/get-detail-custom/';
function Statistic() {
    const [loading, setLoading] = useState(true)
    const [customs, setCustoms] = useState([])
    const [detail, setDetail] = useState([])
    const [custom, setCustom] = useState("N/A")
    const labels = detail.dateViews ? detail.dateViews.map((item) => { return item.date }) : [];
    const datasets = [{
        label: custom,
        data: detail.dateViews ? detail.dateViews.map((item) => { return item.views }) : [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }];
    console.log(labels)
    const labels2 = detail.countryViews ? detail.countryViews.map((item) => { return item.country }) : [];
    const datasets2 = [{
        label: custom,
        data: detail.countryViews ? detail.countryViews.map((item) => { return item.views }) : [],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)', 
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
    }];
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: custom,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    return label;
                }
            }
        },
        elements: {
            line: {
                fill: false,
                tension: 0
            }
        }

    }

    const fetchListCustom = async () => {
        const reponse = await fetch(url)
        const customsJson = await reponse.json()
        setCustoms(customsJson)
        setLoading(false)
    }

    const fetchDetailCustom = async (customName) => {
        let detail = [];
        if (customName !== "N/A") {
            const reponse = await fetch(`${urlDetail}${customName}`)
            detail = await reponse.json()
            setDetail(detail)
        }
        

    }
    useEffect(() => {
        fetchDetailCustom(custom)
    }, [custom])

    useEffect(() => {
        fetchListCustom()
    }, [])

    if (loading) {
        return (
            <section className="section loading">
                <div className="spinner-border text-primary" role="status">
                </div>
            </section>
        )
    }

    return (
        <>
            {/* <div className="row m-l-10 m-r-10">
                <div className="title">
                    <h2>Statistic</h2>
                    <div className="underline"></div>
                </div>
            </div> */}
            <div className="row m-l-10 m-r-10">
                <div className="col-md-3 m-t-42">
                    <span>{customs.length} Results</span>
                    <Scrollbars style={{ height: '80vh' }}>

                        {customs.map((item, index) => {
                            return (
                                <a
                                    title={item.custom}
                                    key={item.custom}
                                    onClick={() => setCustom(item.custom)}
                                    className={`custom-list text-left ${item.custom === custom && 'active-btn'}`}
                                >
                                    <div>{item.custom}</div>
                                    <div>
                                        <div className={`custom-list-title`}>
                                            sptlnk.com/{item.custom}
                                        </div>
                                        <span className="click-count">{item.views} <MdBarChart></MdBarChart></span>
                                    </div>

                                </a>
                            )
                        })}

                    </Scrollbars >
                </div>
                <div className="col-md-9">
                <Scrollbars style={{ height: '80vh' }}>
                    <Line
                        options={options}
                        data={{
                            labels: labels,
                            datasets: datasets
                        }}

                    />
                    <div className="col-md-4 doughnut-chart">
                    <Doughnut
                        data={{
                            labels: labels2,
                            datasets: datasets2
                        }}
                    />
                    </div>

                    </Scrollbars >
                </div>
            </div>
        </>
    )
}
export default Statistic;