import { useState } from 'react';
import Navbar from './Navbar';
import sendSortLink from './../services/api.service'
import { useNotification } from "./../Notifications/NotificationProvider";
import { FaCopy } from "react-icons/fa";

export default function Landing() {
    const [url, setUrl] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [custom, setCustom] = useState('');
    const dispatch = useNotification();
    // console.log("re-render")
    // useEffect(() => { 
    //     console.log(imgRef.current);
    // },[])
    const notification = (type, message) => {
        dispatch({
            type: type,
            message: message,
            title: "Successful Request"
        })
    }
    const handleSubmit = () => {

        console.log({ url, custom });
        if(!validate()){
            return;
        }

        sendSortLink(url,custom).then((response) => {
            // console.log(response)
            // console.log("ra")
            // let response = { "rs": 1, "domain": "sptlnk.com", "custom": `${custom}` };
            if (response.rs === 0) {
                notification("ERROR", "Custom link is already exists");
            } else {
                let urlTmp = `https://${response.domain}/${response.custom}`;
                navigator.clipboard.writeText(urlTmp);
                let qr = `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${encodeURI(urlTmp)}&choe=UTF-8`;
                setQrCode(qr);
                notification("SUCCESS", "Copied to clipboard");
            }
        });
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://sptlnk.com/${custom}`);
        notification("SUCCESS", "Copied to clipboard");
    }
    let validate = () => {
        let check = url.includes("spotify.com");
        if (!check) {
            notification("ERROR", "Please enter spotify link");
            return false;
        }

        if (/[^a-zA-Z0-9\-_]/.test(custom) || custom==="") {
            notification("ERROR", "Custom short link invalid");
            return false;
        }
        return true;
    }

    return (
        <>
            <Navbar />
            <section className='hero'>
                <div className='hero-center'>
                    <article className='hero-info'>
                        <h1>
                            Short Links<br /> Long Life <br />
                        </h1>
                        <p>
                            A URL shortener built with tools to help you grow and protect your brand.
                        </p>
                        {/* <button className='btn-c'>Start now</button> */}
                    </article>
                    <article className='hero-images'>
                        <div className="custom-card height-500">
                            <div className="form-group m-b-1r">

                                <input
                                    value={url}
                                    onChange={e => setUrl(e.target.value)}
                                    placeholder="Your spotify url"
                                    className="form-control "
                                />
                            </div>

                            <div className="form-group m-b-1r relative">
                                <span className="text-in">https://sptlnk.com/</span>
                                <input
                                    value={custom}
                                    onChange={e => setCustom(e.target.value)}
                                    placeholder="custom url"
                                    className="form-control p-l-160 "
                                />
                            </div>
                            <div className="m-b-1r">
                                <button onClick={handleSubmit} style={{ width: '100%' }} className="btn btn-block btn-primary">Submit</button>
                            </div>
                            <div className="text-center">
                                {custom != "" && <span>https://sptlnk.com/{custom} <span onClick={handleCopy}><FaCopy /></span></span>}
                                <img src={qrCode} />
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    )
}