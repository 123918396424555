import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useNavigate } from "react-router-dom";

const clientId = "127991228813-45t1q3r7kj748risi5gefc3bc9vo4den.apps.googleusercontent.com";

function Dashboard() {
    const navigate = useNavigate();
    const onSignoutSuccess = () => {
        alert("You have been logged out successfully");
        console.clear();
        navigate("/login");

    };

    return (
        <GoogleLogout
            clientId={clientId}
            onLogoutSuccess={onSignoutSuccess}
            render={renderProps => (
                <button className="btn-c" onClick={renderProps.onClick} disabled={renderProps.disabled}>Logout</button>
              )}
        >
        </GoogleLogout>
    )
}
export default Dashboard;