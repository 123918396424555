import { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Landing from './components/Landing';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Statistic from './components/Statistic';

import './App.css';




function App() {
  return (

    <>
      <div className="wrapper">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/statistic" element={<Statistic />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
